app-rent-collection-conversation-history,
app-debt-collection-conversation-history,
app-renewals-conversation-history,
app-leasing-conversation-history,
app-maintenance-conversation-history {
  .mat-mdc-tab-group {
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-tab-labels {
      border-bottom: 1px solid var(--console-border-color);
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
      .mat-tab-label {
        min-width: 0 !important;
        padding: 0 10px !important;
        height: 40px;
        opacity: 1 !important;
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        .mat-tab-label-content {
          font-style: normal;
          font-weight: 600;
          font-size: 12px !important;
          line-height: 16px;
          letter-spacing: -0.01em;
          color: var(--console-text-color);
          opacity: 0.6;
          user-select: none;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        &.mat-tab-label-active {
          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
          .mat-tab-label-content {
            opacity: 1 !important;
          }
        }
      }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-tab-body-wrapper {
      display: none;
    }

    .mat-ink-bar {
      background-color: #3e91f1 !important;
    }
  }

  .angular-editor {
    .angular-editor-toolbar {
      padding: 6px 8px !important;
      background: #ffffff !important;
      border: none !important;
      border-bottom: 1px solid var(--console-border-color) !important;
      display: flex;

      .angular-editor-toolbar-set {
        display: inline-flex !important;
        gap: 4px !important;
        margin: 0 !important;

        .angular-editor-button {
          border-radius: 4px !important;
          height: 22px !important;
          width: 22px !important;
          min-width: 22px !important;
          font-size: 12px !important;
          padding: 0 !important;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0.4;
          border: none !important;

          &.active {
            background-color: #3e91f11a !important;
            opacity: 1;
          }
        }
      }
    }

    .angular-editor-wrapper {
      .angular-editor-textarea {
        border: none !important;
        min-height: 100px !important;
        margin: 0 !important;
        word-break: break-word !important;
      }
    }
  }

  .content {
    &.tenant {
      .mat-ink-bar {
        background-color: var(--console-purple-color) !important;
      }

      .angular-editor {
        .angular-editor-toolbar {
          .angular-editor-toolbar-set {
            .angular-editor-button {
              &.active {
                background-color: #f5e9fe !important;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

app-rent-collection-info-and-actions,
app-debt-collection-info-and-actions,
app-renewals-info-and-actions,
app-leasing-info-and-actions {
  .deactivation-container {
    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
    .mat-slide-toggle-content {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.01em;
      color: var(--console-text-color);
    }
  }
}

.mat-mdc-tab {
  flex-grow: unset !important;
  padding: 0 10px !important;
  height: 40px !important;

  &.mdc-tab--active .mdc-tab__text-label {
    font-weight: 600;
    color: var(--console-text-color) !important;
  }
}

app-rent-collection-conversation-history {
  .mat-mdc-tab .mdc-tab-indicator__content--underline {
    border-color: var(--console-button-color) !important;
  }
}

app-debt-collection-conversation-history {
  .mat-mdc-tab .mdc-tab-indicator__content--underline {
    border-color: var(--console-purple-color) !important;
  }
}

app-renewals-conversation-history {
  .mat-mdc-tab .mdc-tab-indicator__content--underline {
    border-color: var(--console-blue-color) !important;
  }
}

app-leasing-conversation-history {
  .mat-mdc-tab .mdc-tab-indicator__content--underline {
    border-color: var(--console-light-blue-color) !important;
  }
}

app-maintenance-conversation-history, app-maintenance-right-pane {
  .mat-mdc-tab .mdc-tab-indicator__content--underline {
    border-color: var(--console-green-color) !important;
  }
}
