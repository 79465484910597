.cdk-overlay-pane:has(.send-menu) {
  margin-bottom: 10px;
}

.send-menu {
  background: #ffffff;
  border: 1px solid #b4c0cf;
  box-shadow: 0 2px 8px rgb(42 43 44 / 15%);
  border-radius: 4px;

  .mat-mdc-menu-content:not(:empty) {
    padding: 9px 0 !important;

    .mat-mdc-menu-item {
      padding: 7px 16px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px !important;
      letter-spacing: -0.01em;
      color: var(--console-text-color);

      &.mat-mdc-menu-item-submenu-trigger {
        padding-right: 32px !important;
      }
    }
  }
}

.payment-plan-toast {
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(42, 43, 44, 0.15) !important;
  border-radius: 4px !important;
  margin-left: 24px;
  margin-top: 4px;

  .mat-mdc-menu-content:not(:empty) {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
    max-height: 200px;

    .title {
      display: flex;
      justify-content: center;
      font-size: 14px;
    }

    .item {
      padding: 7px 15px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.01em;
      color: var(--console-text-color);
    }
  }
}
.debt-toast {
  box-shadow: 0 2px 8px rgba(42, 43, 44, 0.15);
  border-radius: 4px !important;
  margin-left: 24px;
  margin-top: 4px;

  .mat-mdc-menu-content:not(:empty) {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
    max-height: 200px;
    .item {
      display: flex;
      gap: 4px;
      padding: 7px 15px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.01em;
      .hint-text {
        font-size: 12px !important;
      }
      .value {
        font-weight: 600;
      }
    }
  }
}
.opt-selector {
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(42, 43, 44, 0.15) !important;
  border-radius: 4px !important;
  margin-top: 5px !important;
  margin-left: -10px !important;
  padding: 2px 0 !important;

  .mat-mdc-option {
    height: fit-content !important;
    padding: 6px 10px;
    /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
    .mat-option-text {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.01em;
      color: var(--console-text-color);
      display: flex;
      gap: 8px;
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.opt-selector-regular .mat-select-trigger {
  .status {
    display: flex;
    width: 100%;
    gap: 8px;
  }
}

mat-drawer {
  &.right-pane-container {
    border: none !important;
    background: none !important;
    min-width: 50vw;
    height: calc(100% - 64px);
    top: 64px;
    box-shadow: 0 0 10px rgb(42 43 44 / 8%);
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-focus-overlay {
  display: none;
}

.conversation-popup,
.yaml-actions-popup {
  .mat-mdc-dialog-container {
    padding: 0;
    border-radius: 8px;
    box-shadow: -4px 10px 20px 0px rgba(42, 43, 44, 0.05);
  }
}

.mat-calendar {
  tr[aria-hidden='true'] {
    display: none;
  }

  .mat-calendar-body-label {
    visibility: hidden;
  }
}

.mat-calendar {
  &.custom-calendar {
    border-radius: 10px;
    padding: 0;

    .mat-calendar-header {
      padding: 0;

      .mat-calendar-controls {
        margin: 0;
        align-items: center;
        justify-content: space-between;
        gap: 24px;

        .mat-calendar-spacer {
          display: none;
        }

        .mat-calendar-period-button {
          order: 1;
          width: 150px;
          /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
          .mdc-button__label {
            transform: translateY(-2px);

            span {
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 19px;
              text-align: center;
              text-transform: lowercase;
              display: inline-block;
              font-family: 'Open Sans';

              &::first-letter {
                text-transform: uppercase !important;
              }
            }

            .mat-calendar-arrow {
              display: none;
            }
          }
        }

        .mat-calendar-previous-button {
          order: 0;

          &::after {
            transform: rotateY(180deg);
          }
        }

        .mat-calendar-next-button {
          order: 2;
        }
      }
    }

    .mat-calendar-content {
      padding: 0 !important;

      mat-month-view,
      mat-multi-year-view,
      mat-year-view {
        .mat-calendar-table {
          .mat-calendar-table-header {
            th {
              height: 32px;
              width: 32px;
              padding: 0;

              span {
                font-family: 'Open Sans';
                font-size: 14px;
              }
            }

            .mat-calendar-table-header-divider {
              display: none;
            }
          }

          .mat-calendar-body {
            .mat-calendar-body-label {
              opacity: 0;
            }

            .mat-calendar-body-cell-container {
              .mat-calendar-body-cell {
                .mat-calendar-body-cell-content {
                  font-size: 14px;
                  font-family: 'Open Sans';

                  &.mat-calendar-body-today {
                    font-weight: 600;
                    border: none;
                  }

                  &.mat-calendar-body-selected {
                    color: white;
                    background: var(--console-light-blue-color);
                    box-shadow: none;
                  }
                }
              }
              .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
                > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
                  .mat-calendar-body-comparison-identical
                ):not(.mat-calendar-body-comparison-different) {
                background: var(--console-light-blue-color-50);
                color: white;
              }
            }
          }
        }
      }

      mat-multi-year-view,
      mat-year-view {
        .mat-calendar-table {
          .mat-calendar-body {
            .mat-calendar-body-cell-container {
              .mat-calendar-body-cell {
                .mat-calendar-body-cell-content {
                  width: 56px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.mat-mdc-icon-button {
  &.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 24px;
    padding: 0;

    .mat-mdc-button-persistent-ripple,
    .mat-ripple {
      display: none;
    }
  }

  img {
    width: 12px !important;
    height: 12px !important;

    &:hover {
      overflow: hidden;
    }
  }

  .action-panel & .fas,
  .fa {
    padding: 10px;
    font-size: 16px;
    background: var(--console-icon-button-color);
    color: var(--console-icon-color);
    border-radius: 4px;
  }

  .fa-pencil,
  .fa-trash-alt {
    background: none;
  }
}

.companies-container .mdc-icon-button {
  padding: 0 !important;
  margin-right: 2px !important;

  .fas,
  .fa,
  .far {
    font-size: 18px;
  }
}

mat-form-field.mat-mdc-form-field {
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  letter-spacing: -0.01em !important;

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mat-mdc-form-field-flex {
    .mdc-floating-label {
      font-size: 12px;
      top: 16px;

      &--float-above {
        top: 28px;
      }
    }
  }

  .mat-mdc-form-field-infix {
    border-top: 2px solid transparent !important;
    min-height: 32px !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    textarea {
      margin: 4px 0;
      height: 18px;
    }

    mat-select {
      padding: 8px 0 0 !important;

      .mat-select-arrow-wrapper {
        position: relative;
        top: 4px;
      }

      .mat-select-min-line {
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
      }
    }
  }
}

.mat-datepicker-toggle {
  img,
  svg {
    width: 12px !important;
    height: 12px !important;

    &:hover {
      overflow: hidden;
    }
  }

  .mdc-icon-button {
    margin-right: 10px;
  }
}

.mat-mdc-dialog-surface {
  padding: 20px 20px 10px;
}

.mdc-tooltip__surface {
  background-color: var(--console-content-color) !important;
  color: var(--console-text-color) !important;
  padding: 10px 15px !important;
}

.mat-mdc-chip-set {
  min-width: fit-content;
  margin-top: 10px;

  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
    background-color: var(--console-light-blue-color) !important;
  }
}

.mat-mdc-slide-toggle .mdc-switch__ripple::after,
.mat-mdc-checkbox .mdc-checkbox__ripple,
.mat-mdc-radio-button .mdc-radio__background::before,
.mat-mdc-tab.mdc-tab--active .mdc-tab__ripple::before {
  background: none !important;
}

.mat-mdc-menu-item {
  min-height: unset !important;
}

.mat-switch-selected-track-outline-color,
.mdc-switch--selected .mdc-switch__track::after,
.mdc-switch:enabled:focus:not(:active) .mdc-switch__track::after,
.mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after,
.mdc-switch:not(.mdc-switch--unselected) .mdc-switch__shadow {
  background: var(--console-button-color) !important;

  .leasing & {
    background: var(--console-light-blue-color) !important;
  }
  .renewals & {
    background: var(--console-blue-color) !important;
  }
  .maintenance & {
    background: var(--console-green-color) !important;
  }
}

.mat-pseudo-checkbox-full {
  margin-right: 10px !important;
  width: 16px !important;
  height: 16px !important;
  border-color: var(--console-checkbox-border-color) !important;

  &.mat-pseudo-checkbox-checked {
    background-color: var(--console-button-color) !important;
    border-color: var(--console-button-color) !important;
  }

  &::after {
    width: 8px !important;
  }

  &.mat-pseudo-checkbox::after,
  &.mat-pseudo-checkbox-checked::after {
    border-width: 2.5px;
  }
}

.mat-mdc-notch-piece {
  border-width: 1px !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mat-mdc-notch-piece {
  border-color: var(--console-input-border-color);
}

.mdc-notched-outline__notch {
  border-left: none !important;
}

.mdc-text-field--focused:not(.mdc-text-field--invalid),
.mdc-text-field:hover {
  .mat-mdc-notch-piece {
    border: 2px solid var(--console-button-color) !important;

    &.mdc-notched-outline__leading {
      border-right: 0 !important;
    }

    &.mdc-notched-outline__trailing {
      border-left: 0 !important;
    }

    &.mdc-notched-outline__notch {
      border-width: 0 !important;
      border-bottom-width: 2px !important;
    }
  }
}

.mat-drawer-container {
  background: none;
}

.mdc-text-field:hover:not(.mdc-text-field--focused)
  .mat-mdc-notch-piece.mdc-notched-outline__notch:not(:has(.mdc-floating-label--float-above)) {
  border-top-width: 2px !important;
}

.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0 !important;
}
