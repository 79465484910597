@import '@angular/material/prebuilt-themes/indigo-pink.css';
/* You can add global styles to this file, and also import other style files */

.row {
  --bs-gutter-x: 0;
}
body {
  font-family: 'Open Sans' !important;
  letter-spacing: -0.01em;
  background: var(--console-color);
  font-size: var(--console-font-size);
  overflow: hidden;
}

*::-webkit-scrollbar {
  width: 4px;
  height: 8px;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb {
  background: #ced2d4 !important;
  border-radius: 4px !important;
  outline: none !important;
}

///////////////////////////////////////////////////////////// Start global colors and font sizes ///////////////////////////////////////
/////////////////////////////////////////////////////

.console-color {
  color: var(--console-color) !important;
}
.console-color-bg {
  background: var(--console-color) !important;
}

////////////////

.console-content-color {
  color: var(--console-content-color) !important;
  span {
    color: var(--console-content-color) !important;
  }
}
.console-content-color-bg {
  background: var(--console-content-color) !important;
}

///////////////////////////
.console-text-color {
  color: var(--console-text-color) !important;
}
.console-text-color-bg {
  background: var(--console-text-color) !important;
}

////////////////

.console-text-light-color {
  color: var(--console-text-light-color) !important;
}
.console-text-light-color-bg {
  background: var(--console-text-light-color) !important;
}

////////////////

.console-text-deep-light-color {
  color: var(--console-text-deep-light-color) !important;
}
.console-text-deep-light-color-bg {
  background: var(--console-text-deep-light-color) !important;
}

//////////////////

.console-icon-color {
  color: var(--console-icon-color) !important;
}
.console-icon-color-bg {
  background: var(--console-icon-color) !important;
}
/////////////////

/////////////////
.console-multi-color {
  color: var(--console-side-nav-color) !important;
}
.console-multi-color-bg {
  background: var(--console-side-nav-color) !important;
}

/////////////////

//////////////////

.console-icon-color-2 {
  color: var(--console-icon-color-2) !important;
}
.console-icon-color-bg-2 {
  background: var(--console-icon-color-2) !important;
}

/////////////////

.console-menu-color {
  color: var(--console-menu-color) !important;
}
.console-menu-color-bg {
  background: var(--console-menu-color) !important;
}

/////////////////

.console-button-color {
  color: var(--console-button-color) !important;
}
.console-button-color-bg {
  background: var(--console-button-color) !important;
}

/////////////////

.console-icon-button-color {
  color: var(--console-icon-button-color) !important;
}
.console-icon-button-color-bg {
  background: var(--console-icon-button-color) !important;
}

//////////////////

.console-active-box-color {
  color: var(--console-active-box-color) !important;
}
.console-active-box-color-bg {
  background: var(--console-active-box-color) !important;
}

/////////////////
.console-row-color {
  color: var(--console-row-color) !important;
}

.light-green-color-bg {
  background: rgba(31, 153, 94, 0.1) !important;
}

.light-blue-color-bg {
  background: rgba(62, 145, 241, 0.1) !important;
}
///////////////

.console-font-title-size {
  font-size: var(--console-font-title-size) !important;
}

///////////////
.console-font-title-2-size {
  font-size: var(--console-font-title-2-size) !important;
}

///////////////
.console-font-title-3-size {
  font-size: var(--console-font-title-3-size) !important;
}

/////////////////

.console-font-size {
  font-size: var(--console-font-size) !important;
}

/////////////////

.console-font-menu-size {
  font-size: var(--console-font-menu-size) !important;
}

.console-button-color-2 {
  color: var(--console-button-color-2) !important;
}

.console-icon-4-color {
  color: var(--console-icon-4-color) !important;
}

.dark-color {
  color: #000000 !important;
}
.light-color {
  color: rgba(42, 43, 44, 0.2) !important;
}
.green-color {
  color: var(--console-badge-green) !important;
}

.light-orange-color-bg {
  background: rgba(255, 144, 101, 0.1) !important;
}
.select-question {
  margin-left: 10px !important;
}

/////////////////

.purple-color {
  color: var(--console-purple-color) !important;
}

.purple-bg {
  background: var(--console-purple-color) !important;
}

/////////////////

.blue-color {
  color: var(--console-blue-color) !important;
}

.blue-bg {
  background: var(--console-blue-color) !important;
}

/////////////////

.light-blue-color {
  color: var(--console-light-blue-color) !important;
}

.light-blue-bg {
  background: var(--console-light-blue-color) !important;
}

///////////////////////////////////////////////////////////// End global colors and font sizes ///////////////////////////////////////
/////////////////////////////////////////////////////

//////////////////////////////////////////// start side nav ////////////////////////////////////
.side-nav {
  background: var(--console-multi-color);
  width: 60px;
  margin-right: 20px;

  .item {
    cursor: pointer;
    width: 40px;
    height: 40px;

    margin-left: 7px;
    border-radius: 4px;
    i {
      color: var(--console-content-color);
      font-weight: 900;
      font-size: 16px;
      line-height: 39px;
      letter-spacing: -0.01em;
    }
  }
  .activeLink {
    background: var(--console-content-color);
    i {
      color: var(--console-button-color);
    }
  }

  &.debt-collection-side-nav {
    background: linear-gradient(92deg, var(--console-purple-color) 22.57%, #ed09d2 79.48%) !important;
    margin-right: 0 !important;
    .activeLink {
      i {
        color: var(--console-purple-color) !important;
      }
    }
  }

  &.renewals-side-nav {
    background: var(--console-dark-blue-color) !important;
    margin-right: 0 !important;
    .activeLink {
      i {
        color: var(--console-dark-blue-color) !important;
      }
    }
  }

  &.leasing-side-nav {
    background: rgb(32, 177, 214);
    background: linear-gradient(90deg, rgba(32, 177, 214, 1) 0%, rgba(67, 184, 214, 1) 50%, rgba(0, 212, 255, 1) 100%);
    margin-right: 0 !important;
    .activeLink {
      i {
        color: var(--console-light-blue-color) !important; // to confirm with designer
      }
    }
  }
}

//////////////////////////////////////////// end side nav ////////////////////////////////////
.font-weight-600 {
  font-weight: 600;
}

.padding-right-8 {
  padding-right: 8px;
}
.width-90 {
  width: 90px;
}
.width-90-p {
  width: 90%;
}

// .mat-icon-button{
//     border-radius:4px !important;
//     width:32px  !important;
//     height:32px  !important;
//     line-height:0px  !important;
//     background:var(--console-icon-button-color);
//     color:var(--console-icon-color);
// }
.square-icon-button {
  border-radius: 4px !important;
  width: 32px !important;
  height: 32px !important;
  line-height: 0px !important;
  background: var(--console-icon-button-color);
  color: var(--console-icon-color);
}
.simple-icon-button {
  width: 32px !important;
  height: 32px !important;
  line-height: 0px !important;
  color: var(--console-icon-color);
}
.box-shadow {
  box-shadow: 0px 0px 10px rgba(42, 43, 44, 0.08);
}

.box-shadow-2 {
  box-shadow: 0px -2px 10px rgba(42, 43, 44, 0.08);
}
.box-shadow-bottom {
  box-shadow: 0px 1px 0px #ececec;
}

.c-sm-box {
  width: 32px;
  height: 32px;
  border-radius: 4px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline
  > div.mat-form-field-wrapper
  > div.mat-form-field-flex
  > div.mat-form-field-infix {
  padding: 5px 0px;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline
  > div.mat-form-field-wrapper
  > div.mat-form-field-flex
  > div.mat-form-field-infix
  > span.mat-form-field-label-wrapper {
  top: -1.4em;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1.1em) scale(0.75);
  width: 133.33333%;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-outline .mat-form-field-wrapper {
  // margin:0px;
  padding-bottom: 0px;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-outline .mat-form-field-flex {
  margin-top: 0px !important;
  top: 3px;
  border-radius: 4px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.c-dropdown.mat-form-field-appearance-outline .mat-form-field-outline {
  color: var(--console-icon-button-color);
  border-radius: 4px;
  background: var(--console-menu-color) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.red-dropdown.mat-form-field-appearance-outline .mat-form-field-outline {
  color: var(--console-button-color);
  border-radius: 4px;
  background: var(--console-bg-light-color);
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.yellow-dropdown.mat-form-field-appearance-outline .mat-form-field-outline {
  color: yellow;
  border-radius: 4px;
  background: rgba(245, 245, 0, 0.205) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.c-input.mat-form-field-appearance-outline .mat-form-field-flex {
  margin-top: 0px !important;
  top: -1px;
  background: var(--console-content-color);
  border-radius: 4px;
}

/* Font color */

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.c-input.mat-form-field-appearance-outline:not(.mat-form-field-disabled)
  .mat-form-field-flex:hover
  .mat-form-field-outline {
  border: none;
  opacity: 1;
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-outline-start,
  .mat-form-field-outline-gap,
  .mat-form-field-outline-end {
    border: none;
  }
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.c-input.mat-form-field-appearance-outline .mat-form-field-outline {
  color: var(--console-content-color);
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.c-input.mat-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-form-field-flex {
  top: 3px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.c-input.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: var(--console-content-color);
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.c-input .mat-form-field-wrapper {
  background: var(--console-content-color);
  border-radius: 4px;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.c-input.mat-mdc-form-field.mat-form-field-appearance-outline
  > div.mat-form-field-wrapper
  > div.mat-form-field-flex
  > div.mat-form-field-infix {
  top: -3px;
}
.c-input.mat-mdc-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon,
.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon {
  color: var(--console-icon-color);
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.input-hover-color.mat-form-field-appearance-outline:not(.mat-form-field-disabled)
  .mat-form-field-flex:hover
  .mat-form-field-outline {
  color: var(--console-button-color);
}
.mat-mdc-select-panel {
  margin-top: 10px !important;
  margin-left: -10px !important;
  min-width: calc(100% + 20px) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-mdc-select .mat-select-trigger .mat-select-arrow {
  color: var(--console-icon-color);
}

.mat-mdc-tooltip {
  white-space: pre-line;
  font-size: 10px !important;
  max-width: 400px !important;
  color: var(--console-text-color) !important;
  background: var(--console-content-color) !important;
  box-shadow: 0px 2px 8px rgba(42, 43, 44, 0.15);
  border-radius: 4px;
}

.mat-pseudo-checkbox {
  color: var(--console-checkbox-border-color);
}
.mat-label {
  color: var(console-text-light-color) !important;
}
.mat-mdc-form-field-hint {
  font-size: 10px;
  padding-top: 4px;
}
.p-title {
  font-size: var(--console-font-title-size) !important;
  font-weight: 600;
}
.p-title-2 {
  font-size: var(--console-font-title-2-size) !important;
  font-weight: 600;
}
.p-title-3 {
  font-size: var(--console-font-title-3-size) !important;
  font-weight: 600;
}
.menu-title {
  line-height: 4 !important;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-size: var(--console-font-size) !important;
  font-weight: 500;
  width: 100px;
  color: var(--console-text-light-color) !important;
}
.active-menu {
  font-weight: 600 !important;
  color: var(--console-text-color) !important;
}
.forgot-password-btn {
  font-size: 10px;
  color: var(--console-text-light-color) !important;
  padding-top: 4px;
}

.spacer {
  flex: 1;
}
///////////////////////////////////////// start line heights   ////////////////////////////////////////////////////////////
///////////////////////////////

.line-height-1 {
  line-height: 1 !important;
}
.line-height-2 {
  line-height: 2 !important;
}
.line-height-3 {
  line-height: 3;
}
.line-height-4 {
  line-height: 4 !important;
}
.line-height-5 {
  line-height: 5;
}

///////////////////////////////////////// end line heights   ////////////////////////////////////////////////////////////
///////////////////////////////

///////////////////////////////////////// start font size   ////////////////////////////////////////////////////////////
///////////////////////////////

.font-size-10px {
  font-size: 10px;
}
.font-size-13px {
  font-size: 13px;
}
.font-size-14px {
  font-size: 14px;
}
.font-size-18px {
  font-size: 18px;
}
.font-size-24px {
  font-size: 24px;
}

///////////////////////////////////////// end font size  ////////////////////////////////////////////////////////////
///////////////////////////////

///////////////////////////////////////// start supervisor  ///////////////////////////////
.custom-dropdown-2 {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-infix {
    border-top: 0.7em solid transparent;
    border-bottom: 0.2em solid transparent;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-select-arrow-wrapper {
    padding-top: 5px;
  }
}

.mat-form-field-appearance-outline:hover {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-outline {
    color: var(--console-button-color);
    border: none !important;
  }
}
.overflow-hidden {
  overflow: hidden;
  .content {
    overflow: auto;
    height: 200px;
  }
}
.right-checbox {
  .mat-pseudo-checkbox {
    position: absolute;
    right: 0px;
  }
}
.dropdown-menu-size {
  min-width: calc(28vw + 20px) !important;
}
.dropdown-top {
  position: relative;
  top: -7px;
}
.dropdown-panel {
  margin-left: 7px;
  margin-top: 30px !important;
}
.simple-square-button {
  font-size: 17px;
  line-height: 0px !important;
  width: 32px !important;
  height: 32px !important;
  color: var(--console-checkbox-border-color);
}

///////////////////////////////////////// end supervisor  ///////////////////////////////

.c-hr {
  border-top: 1px solid #e9ebec;
  margin-right: 15px;
  margin-left: 15px;
  box-sizing: border-box;
  border-radius: 1px;
  transform: matrix(1, 0, 0, -1, 0, 0);
}
.c-hr-2 {
  border-top: 1px solid var(--console-color);
  transform: rotate(180deg);
}
.c-hr-3 {
  border-top: 1px solid var(--console-color);
}
.c-table {
  margin-top: 15px;

  .c-heading {
    background: var(--console-menu-color);
    color: var(--console-text-light-color);
    line-height: 3;
    padding-left: 20px;
    padding-right: 20px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .c-table-body {
    line-height: 2;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background: var(--console-content-color);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    .c-t-row {
      border-bottom: 1px solid var(--console-row-color);
    }
  }
  .c-table-height {
    height: 70vh;
  }
  .c-scroll::-webkit-scrollbar-thumb {
    background: #ced2d4 !important;
    border-radius: 4px !important;
    outline: none !important;
  }
}
.c-right-menu {
  right: -600px;
  position: absolute;
  animation: slide 0.5s forwards;
  top: 57px;
  z-index: 1000;
  height: 91.3vh;
  background: var(--console-content-color);
  // background:red;
  width: 69vw;
  box-shadow: -4px 10px 20px rgba(42, 43, 44, 0.05);
  overflow: hidden;
}

.visibility-hidden {
  visibility: hidden;
}

.opacity-0 {
  opacity: 0;
}

@keyframes slide {
  100% {
    right: 0;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.loading-dialog {
  .mat-mdc-dialog-surface {
    background: transparent;
    box-shadow: none;
  }
}

.bottom-button {
  position: absolute;
  right: 4% !important;
  bottom: 10px;
  button {
    width: 100px;
  }
}
.bottom-update-button {
  // position:absolute;right:4% !important;bottom:200px;
  button {
    width: 100px;
  }
}
.bottom-update-pass-button {
  position: absolute;
  right: 4% !important;
  bottom: 10px;
}
.bottom-verification-button {
  position: absolute;
  right: 4% !important;
  bottom: 100px;
}
.chart-title {
  position: absolute;
  bottom: 0p;
  left: 20px;
  top: 80px;
  font-weight: 600;
}

.border-radius-top {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.border-radius-bottom {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.border-radius-4 {
  border-radius: 4px;
}
.border-radius-12 {
  border-radius: 12px;
}

.c-border {
  border: 1px solid rgba(42, 43, 44, 0.1);
}

.border-none {
  border-right: 1px solid rgba(42, 43, 44, 0.1);
}

.padding-none {
  padding: none;
}

.p-10 {
  padding: 10px;
}
.pr-19 {
  padding-right: 19px;
}

.pl-160 {
  padding-left: 160px;
}

.pr-30 {
  padding-right: 30px;
}
.mr-2px {
  margin-right: 2px;
}
.mb-3px {
  margin-bottom: 3px;
}
.mb-50px {
  margin-bottom: 50px;
}
.mt-10px {
  margin-top: 10px;
}
.paginator {
  background: var(--console-color) !important;
}
.timespan-paginator .mat-mdc-paginator-page-size {
  position: fixed;
  right: 46px;
}

.mat-mdc-paginator-icon {
  background: var(--console-content-color);
  border-radius: 4px;
  color: var(--console-icon-color-2);
  padding: 2px;
}
.timespan-paginator .mat-mdc-paginator-container {
  margin-right: 50vw;
}
.conversation-paginator .mat-mdc-paginator-container {
  width: auto !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.paginator .mat-form-field-underline {
  display: none;
}
.paginator .mat-mdc-select {
  background: var(--console-content-color);
  padding: 5px;
  border-radius: 4px;
}
.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: var(--console-button-color);
}
.mat-mdc-checkbox {
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  .mat-checkbox-ripple .mat-ripple-element {
    background-color: var(--console-checkbox-border-color) !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  .mat-checkbox-frame {
    border-color: var(--console-checkbox-border-color) !important;
  }
}
/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
.mat-option-text,
.mdc-list-item__primary-text {
  color: var(--console-text-color) !important;
  font-size: 12px !important;
  font-family: 'Open Sans' !important;
  letter-spacing: normal !important;

  img {
    margin-right: 8px;
  }
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-focused .mat-form-field-label {
  /*change color of label*/
  color: var(--console-text-deep-light-color) !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: var(--console-button-color);
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background: none !important;
}

.mat-mdc-text-field-wrapper:hover .mat-mdc-form-field-focus-overlay,
.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}

.eye-icon-sv {
  color: var(--console-icon-color);
  font-size: var(--console-font-title-2-size);
}

.ledger-btn {
  border-radius: 4px;
  font-weight: 550;
  font-size: var(--console-font-size);
  width: 147px;
  i {
    color: var(--console-icon-color-2);
    font-size: var(--console-font-title-3-size);
  }
  padding-left: 3px !important;
}
.active-btn {
  color: #fdfdfd;
  background: var(--console-button-color);
  font-weight: 400;
}

//////////////////////////////////////////////////////////// start conversation /////////////////@at-root

.con-history-tabs {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label-active {
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-tab-label-content {
      &:after {
        width: 10%;
        left: 0px;
      }
      color: var(--console-text-color);
      font-weight: 600;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  div[aria-posinset~='2'].mat-tab-label-active {
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-tab-label-content {
      &:after {
        width: 90%;
        left: 8px;
      }
      color: var(--console-text-color);
    }
  }

  .onboard-tab-dot {
    margin-left: 4px;
    width: 16px;
    height: 16px;
    border-radius: 10px;
    background: var(--console-button-color-2);
    text-align: center;
    line-height: 17px;
    color: var(--console-content-color) !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label {
    opacity: 1 !important;
    color: var(--console-text-light-color);
    font-size: var(--console-font-size);
    padding: 0 8px !important;
    min-width: 60px !important;
    font-weight: 600;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label-container {
    margin-left: 20px;
    margin-right: 20px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-labels {
    border-bottom: 1px solid var(--console-border-color);
  }
}

.mat-mdc-tab-group .mat-ink-bar {
  background-color: var(--console-button-color) !important;
}

.mat-mdc-tab-header {
  border-bottom: none;
}
.decions-conversation {
  background: rgba(62, 145, 241, 0.1);
  border: 1px solid var(--console-button-color-2);
  border-radius: 12px;
  color: var(--console-button-color-2);
  font-size: 10px;
  text-align: center;
  width: max-content;
  margin: auto;
}
.decision-dropdown {
  top: -12px;
}
.payment-plan-icon-btn {
  background: var(--console-icon-button-color);
  i {
    color: var(--console-icon-color-2);
    vertical-align: baseline !important;
  }
}
.payment-plan-icon-btn-active {
  background: var(--console-bg-light-color);
  i {
    color: var(--console-button-color);
    vertical-align: baseline !important;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background: var(--console-button-color);
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.renewals .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background: var(--console-blue-color);
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.leasing .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background: var(--console-light-blue-color);
}
/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background: #ffffff;
  margin-left: -2px;
  margin-top: 5.6px;
}
/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-slide-toggle-bar {
  width: 32px !important;
  height: 21px !important;
  border-radius: 10px !important;
  background-color: var(--console-icon-color);
}
/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-slide-toggle-thumb {
  width: 16px !important;
  height: 16px !important;
  margin-top: 6px;
  margin-left: 3px;
  position: absolute;
  top: -3px;
}
/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-slide-toggle-thumb-container {
  top: 0px !important;
}
.icon-dropdown-panel {
  position: relative;
  top: -7px;
  left: -22px;
  i {
    font-size: var(--console-font-title-3-size);
  }

  font-size: var(--console-font-size);
  //   padding: 10px;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.icon-dropdown .mat-select-trigger .mat-select-value .mat-select-value-text {
  position: relative;
  top: 0.5px;
}
.plan-line {
  border-bottom: 1px solid var(--console-row-color);
}
.plan-line2 {
  border-bottom: 1px solid #dae0e7;
}
//////////////////////////////////////////////////////////// end conversation /////////////////////////

/////////////////////////////////////////// chat page start ///////////////////////////////////////////

.chat-send-msg-btn {
  width: 140px;
  height: 32px;
  background: var(--console-checkbox-border-color);
  font-size: var(--console-font-size);
  font-weight: 600;
  line-height: 1 !important;
}
.chat-done-btn {
  width: 140px;
  height: 32px;
  background: var(--console-checkbox-border-color);
  font-size: var(--console-font-size);
  font-weight: 600;
  line-height: 1 !important;
}
.chat-history-box-height {
  height: 40vh;
  overflow: auto;
}
.display-inline {
  display: inline;
}
.chat-status {
  border-radius: 12px;
  border: 1px solid var(--console-border-color);
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 1px;
}
.chat-circle-icon {
  //    color:#06CB6C;
  color: var(--console-icon-color);
}
.report-box {
  background: var(--console-content-color);
  height: 100vh;
  position: absolute;
  right: 0;
  top: 58px;
  width: 34vw;
  .hr {
    width: 34vw;
    position: absolute;
    right: 0.2vw !important;
    bottom: 70px;
    border-top: 1px solid var(--console-color);
    transform: rotate(180deg);
  }
}
.conversation-verification-box-height {
  overflow: auto;
  height: 69vh;
}

.circle-number {
  margin-left: 4px;
  min-width: 18px;
  min-height: 18px;
  border-radius: 10px;
  background: var(--console-button-color);
  text-align: center;
  // line-height:18px;
  color: var(--console-content-color) !important;
  font-size: var(--console-font-size);
  display: inline-block;
  padding-left: 4px;
  padding-right: 5px;
}

.info-heading {
  font-size: 10px;
  padding-right: 3px;
  padding-left: 3px;
}
.info-content {
  color: var(--console-text-color);
  font-size: var(--console-font-size);
}
.info-icon {
  color: var(--console-icon-color-2);
  font-size: var(--console-font-size);
}

/////////////////////////////////////////// chat page end ///////////////////////////////////////////

///////////////////////////////////////// start office ////////////////////////////////////////@at-root

.office-action-btn {
  i {
    position: relative;
    top: -1px;
  }
  border-radius: 4px !important;
  width: 23px !important;
  height: 23px !important;
  line-height: 0px !important;
  background: var(--console-content-color);
  border: 1px solid var(--console-border-color) !important;
  color: var(--console-icon-color);
}
.mat-mdc-menu-item {
  height: 30px !important;
  line-height: 1 !important;
}

.action-green-box {
  color: #1f995e;
  background: rgba(31, 153, 94, 0.1);
  text-align: center;
  padding: 10px 15px 10px 15px;
  margin-top: 10px;
  font-weight: bold;
  border-radius: 4px;
  margin-bottom: 10px;
}

////////////////////////////////////////////// end office //////////////////////////

//////////////////////////////////////// start sv-learning ////////////////////////////////////@at-root

.sv-expansion {
  .mat-expansion-indicator::after {
    color: rgba(180, 192, 207, 1);
  }
  /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
  mat-list-item {
    max-height: 48px !important;
  }
  .mat-mdc-list-item:hover {
    background: none;
  }

  mat-expansion-panel {
    background: var(--console-content-color) !important;
    box-shadow: 0px 4px 20px rgba(42, 43, 44, 0.05) !important;
    position: absolute;
    width: 30vw;
    z-index: 1;
  }

  mat-expansion-panel-header {
    max-height: 43px !important;
    font-size: var(--console-font-size) !important;
    border-bottom: 1px solid #f0f0f0;
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 1px;
    border-radius: 4px !important;
  }
  mat-expansion-panel-header:hover {
    background: var(--console-content-color) !important;
  }

  mat-expansion-panel-header mat-list-item {
    max-height: 48px !important;
  }
  .mat-expansion-panel-body {
    overflow: auto;
    height: 30vh;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
  .mat-list-item-content {
    font-size: var(--console-font-size) !important;
    padding: 5px 0 5px 26px;
    cursor: pointer;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
  .mat-list-item-content:hover {
    max-height: 48px;
    background: var(--console-row-color);
  }
  .mat-expansion-panel-body {
    // padding-bottom: 0px;
    padding-bottom: 0px;
    padding: 0px;
  }
  .circle-text {
    border-radius: 18px;
    width: 26px;
    height: 24px;
    text-align: center;
    padding-top: 3px;
    background: var(--console-button-color);
    color: var(--console-content-color);
  }
}
.filter-btn {
  border: 1px solid var(--console-border-color) !important;
  border-radius: 12px !important;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 4px;
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 0px !important;
  height: 23px !important;
}
.active-filter {
  color: var(--console-button-color) !important;
  border: 1px solid var(--console-button-color) !important;
  background: var(--console-bg-light-color) !important;
}

.activeConversation {
  background: var(--console-bg-light-color);
  .c-hr-2 {
    border: 0;
  }
}
.svl-conversation-height {
  overflow: auto;
  height: 57vh;
}
.history-log-conversations-height {
  overflow: auto;
  height: 50vh;
}
.new-status {
  background: rgba(31, 153, 94, 0.1);
  color: var(--console-badge-green);
  border-radius: 10px;
  height: 18px;
  margin-top: 2px;
}
.handling-status {
  padding-top: 1px;
  color: var(--console-button-color);
  background: var(--console-bg-light-color);
  border-radius: 10px;
}
.open-envelop {
  background: #e7f2fc;
  color: var(--console-text-deep-light-color);
  border-radius: 10px;
  height: 18px;
  margin-top: 2px;
}
.seen {
  color: var(--console-badge-blue);
}
.svl-history-height {
  overflow: auto;
  height: 73.5vh;
}
.sv-learning-group {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label {
    height: 43px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-labels {
    border-bottom: none !important;
  }
}

.learning-dropdown {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline
    > div.mat-form-field-wrapper
    > div.mat-form-field-flex
    > div.mat-form-field-infix {
    padding: 10px 0px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline
    > div.mat-form-field-wrapper
    > div.mat-form-field-flex
    > div.mat-form-field-infix
    > span.mat-form-field-label-wrapper {
    top: -1em;
  }
}

.right-border {
  border-right: 1px solid rgba(42, 43, 44, 0.2);
}
.padding-0-dialog-container .mat-mdc-dialog-container {
  padding: 0px;
  overflow: hidden;
}
/////////////////////////////////////////// end sv-learning ////////////////////////////////////////

///////////////////////////////////////// start media queries    ////////////////////////////////////////////////////////////
///////////////////////////////
@media only screen and (max-width: 3000px) {
  /* its 67*/
  .svl-conversation-height {
    height: 75vh;
  }
  .svl-history-height {
    height: 83vh;
  }
  .sv-expansion mat-expansion-panel {
    width: 31.6vw;
  }
}
@media only screen and (max-width: 2100px) {
  /* its 50 */
  .c-right-menu {
    height: 94vh;
  }
  .chat-history-box-height {
    height: 62.5vh;
  }
  .conversation-verification-box-height {
    height: 84vh;
  }
  .svl-conversation-height {
    height: 70vh;
  }
  .history-log-conversations-height {
    overflow: auto;
    height: 65vh;
  }
  .sv-expansion mat-expansion-panel {
    width: 31.4vw;
  }
}

@media only screen and (max-width: 1920px) {
  /* its 67*/
  .c-right-menu {
    height: 93.3vh;
  }
  .chat-history-box-height {
    height: 58.5vh;
  }
  .conversation-verification-box-height {
    height: 78vh;
  }
  .svl-conversation-height {
    height: 68vh;
  }
  .history-log-conversations-height {
    overflow: auto;
    height: 63vh;
  }
  .svl-history-height {
    height: 79vh;
  }
  .sv-expansion mat-expansion-panel {
    width: 31.2vw;
  }
}

@media only screen and (max-width: 1800px) {
  /* its 75*/
  .c-right-menu {
    height: 93vh;
  }
  .chat-history-box-height {
    height: 54vh;
  }
  .conversation-verification-box-height {
    height: 76vh;
  }
  .svl-conversation-height {
    height: 65vh;
  }
  .history-log-conversations-height {
    overflow: auto;
    height: 58vh;
  }
  .svl-history-height {
    height: 77.3vh;
  }
  .sv-expansion mat-expansion-panel {
    width: 31vw;
  }
}

@media only screen and (max-width: 1600px) {
  /* its 80*/
  .c-right-menu {
    height: 92.2vh;
  }
  .chat-history-box-height {
    height: 51.5vh;
  }
  .conversation-verification-box-height {
    height: 74.5vh;
  }
  .svl-conversation-height {
    height: 63vh;
  }
  .history-log-conversations-height {
    overflow: auto;
    height: 55vh;
  }
  .svl-history-height {
    height: 76vh;
  }
  .sv-expansion mat-expansion-panel {
    width: 30.8vw;
  }
}
@media only screen and (max-width: 1500px) {
  /* its 90*/
  .c-right-menu {
    height: 91.3vh;
  }
  .c-table .c-table-height {
    height: 65vh;
  }
  .chat-history-box-height {
    height: 45.7vh;
  }
  .conversation-verification-box-height {
    height: 71.5vh;
  }
  .svl-conversation-height {
    height: 59vh;
  }
  .history-log-conversations-height {
    overflow: auto;
    height: 50vh;
  }
  .svl-history-height {
    height: 73.8vh;
  }
  .sv-expansion mat-expansion-panel {
    width: 30.4vw;
  }
}
@media only screen and (max-width: 1400px) {
  /* its 100*/
  .c-table .c-table-height {
    height: 63vh;
  }
  .chat-history-box-height {
    height: 40vh;
  }
  .conversation-verification-box-height {
    height: 69vh;
  }
  .svl-conversation-height {
    height: 55vh;
  }
  .history-log-conversations-height {
    overflow: auto;
    height: 45vh;
  }
  .svl-history-height {
    height: 71.5vh;
  }
  .sv-expansion mat-expansion-panel {
    width: 30vw;
  }
}

///////////////////////////////////////// end media queries   ////////////////////////////////////////////////////////////
///////////////////////////////
.select-all-container {
  display: inline-block;
  position: relative;
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  mat-checkbox {
    opacity: 0;
    z-index: 1;
  }

  .icon {
    position: absolute;
    height: 18px;
    top: 2px;
    left: 0;
    width: 18px;
  }
}

.pre-line {
  white-space: pre-line;
}
.cdk-overlay-connected-position-bounding-box {
  margin-top: 2px !important;
}

.hide {
  display: none !important;
}

.pmf-dropdown-panel {
  margin: 0 !important;
  text-transform: capitalize;
}

.pmf-dropdown-actions {
  display: flex;
  padding: 12px;
  justify-content: space-between;

  .dropdown-btn {
    background: #fff;
    line-height: 20px;
    width: 48px;
    outline: none;
    border: 0;
    border-radius: 4px;
    font-weight: 600;

    &__apply {
      color: var(--console-button-color);
    }

    &__cancel {
      color: var(--console-icon-color);
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.custom-dropdown-actions {
  display: flex;
  padding: 12px;
  justify-content: flex-end;
  border-top: 1px solid var(--console-border-color);
  user-select: none;

  .dropdown-btn {
    font-weight: 600;
    font-size: 12px;
    color: white !important;
    border: none;
    outline: none;
    border-radius: 4px;
    padding: 0 10px;
    min-width: 60px;
    min-height: 32px;

    &__save {
      background: var(--console-blue-color);

      &.light-blue {
        background: var(--console-light-blue-color);
      }
    }
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.mat-datepicker-actions {
  padding: 8px !important;
}

.timelist-select {
  width: 100px;
  margin-right: auto;
}

.mat-drawer-container {
  background-color: unset !important;
}

.background-purple {
  background: var(--console-purple-color) !important;
}

.background-blue {
  background: var(--console-blue-color) !important;
}

.background-light-blue {
  background: var(--console-light-blue-color) !important;
}

.mat-mdc-progress-spinner circle,
.mat-mdc-progress-spinner circle {
  stroke: var(--console-button-color);
}

.theme-color-purple.mat-mdc-progress-spinner circle {
  stroke: var(--console-purple-color);
}

.theme-color-blue.mat-mdc-progress-spinner circle {
  stroke: var(--console-blue-color);
}

.theme-color-light-blue.mat-mdc-progress-spinner circle {
  stroke: var(--console-light-blue-color);
}

.general-data,
.floorplan,
.tour-data,
.amenities {
  .mat-expansion-panel-body {
    font-family: 'Open Sans';
    background: var(--console-color);
    padding: 16px 12px;
    border-top: 1px solid var(--console-border-color);
  }
}

.preferred-container {
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-select-value {
    white-space: unset;
  }
}

.mdc-button.cancel-btn {
  background: var(--console-icon-color) !important;
  color: var(--console-content-color) !important;
}

.mat-mdc-menu-item-text {
  font-size: 14px !important;
  font-family: 'Open Sans' !important;
  letter-spacing: normal !important;
}

.mat-mdc-select-value-text {
  font-size: 12px;
  font-family: 'Open Sans' !important;
  letter-spacing: normal !important;
}

.mat-mdc-paginator-page-size-select {
  width: 56px !important;

  .mdc-text-field {
    padding: 0;

    .mat-mdc-select {
      padding: 5px 10px !important;
    }
  }
}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: var(--console-button-color) !important;
}

.mat-ripple-element {
  display: none;
}

.angular-editor-toolbar-set .fa {
  font: unset !important;
  font-family: 'Font Awesome 5 Pro' !important;
  font-weight: 900 !important;
}

@import 'assets/scss/pages';
@import 'assets/scss/override';
@import 'assets/scss/input-fields';
@import 'assets/scss/datepicker';
