.input-container {
  position: relative;
  display: inline-block;
  width: 100%;

  label {
    position: absolute;
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: var(--console-text-color) 99;
    left: 6px;
    padding: 0 4px;
    top: 8px;
    transition: all 200ms;
    user-select: none;
    cursor: text;
    z-index: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: #ffffff;
    overflow: hidden;
    max-width: 80%;
  }

  input,
  textarea,
  mat-select {
    position: relative;
    background: transparent;
    border: 1px solid #e9ebec;
    border-radius: 4px;
    height: 32px;
    outline: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 19px;
    letter-spacing: -0.01em;
    color: var(--console-text-color);
    box-sizing: border-box;
    padding: 0 10px;
    transition: all 200ms;
    width: 100%;
    z-index: 1;

    &::placeholder {
      opacity: 0;
    }

    &[disabled] {
      opacity: 0.5;
    }
  }

  textarea {
    padding: 5px 30px 0 10px;
    height: auto;
    max-height: 200px;
    max-width: 100%;
    min-height: 32px;
    resize: vertical;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  mat-select {
    display: flex;
    align-items: center;
    cursor: pointer;
    /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    &.mat-select-empty {
      /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
      .mat-select-placeholder {
        opacity: 0;
      }
    }
  }

  .suffix {
    position: absolute;
    top: 12px;
    right: 10px;
    z-index: 1;

    &.clickable {
      padding: 4px;
      border-radius: 50%;
      top: 8px;
      right: 6px;
      cursor: pointer;
    }
  }

  .error {
    height: 0;
    transition: all 200ms;
    overflow: hidden;
    font-style: normal;
    font-weight: 400;
    font-size: 10px !important;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #db234f;

    &.visible {
      height: 0;
    }
  }

  .hint {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: var(--console-text-color) 99;
    margin: 4px 0 0;
    height: 15px;
    user-select: none;

    &::before {
      position: relative;
      top: 2px;
      margin-right: 4px;
      //content: url('../images/input-hint-12.svg');
    }
  }

  &:has(input[required]),
  &:has(textarea[required]),
  &:has(mat-select[required]) {
    label {
      &::after {
        content: ' *';
      }
    }
  }

  &:has(input:focus),
  &:has(textarea:focus),
  &:has(input:-webkit-autofill),
  &:has(mat-select[aria-expanded='true']) {
    input,
    textarea,
    mat-select {
      position: unset;
      border: 1px solid #37474f;

      &::placeholder {
        opacity: 0.6;
      }
    }

    label {
      top: -8px !important;
      font-size: 12px !important;
      color: #37474f;
      background: #ffffff;
    }
  }

  &:has(input:not(:placeholder-shown)),
  &:has(textarea:not(:placeholder-shown)),
  &:has(mat-select:not(.mat-mdc-select-empty)) {
    label {
      top: -8px !important;
      font-size: 12px !important;
    }

    input,
    textarea,
    mat-select {
      position: unset;
    }
  }

  &:has(.ng-touched.ng-invalid) {
    label {
      color: #db234f !important;
    }

    input,
    textarea,
    mat-select {
      border: 1px solid #db234f;
      color: #db234f;
      /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
      &::placeholder,
      .mat-select-arrow {
        color: #db234f !important;
      }
    }

    .error {
      margin: 4px 0 0;
      height: 15px;
      visibility: visible;
    }

    .hint {
      display: none;
    }
  }

  &:has(.suffix) {
    input {
      padding-right: 32px;
    }
  }

  &.large {
    label {
      top: 12px;
    }

    input,
    textarea,
    mat-select {
      height: 40px;

      &:has(input:not(:placeholder-shown)),
      &:has(textarea:not(:placeholder-shown)) {
        label {
          top: -8px !important;
          font-size: 12px !important;
        }
      }
    }
  }
}

.ng-submitted:has(.input-container) {
  .input-container {
    &:has(.ng-invalid) {
      label {
        color: #db234f !important;
      }

      input,
      textarea,
      mat-select {
        border: 1px solid #db234f;
        color: #db234f;
        /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
        &::placeholder,
        .mat-select-arrow {
          color: #db234f !important;
        }
      }

      .error {
        margin: 4px 0 0;
        height: 15px;
        visibility: visible;
      }

      .hint {
        display: none;
      }
    }
  }
}
