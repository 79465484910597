// Datepicker styles
.mat-calendar-table-header {
  background: unset !important;
}

.mat-calendar-table tr {
  border-bottom: unset !important;
}

.orange .mat-calendar-body-selected {
  background: var(--console-button-color) !important;
  color: var(--console-color) !important;
}

.orange .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: var(--console-button-color);
}

.orange
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--console-button-color);
  color: white;
}

.purple .mat-calendar-body-selected {
  background: var(--console-purple-color) !important;
  color: var(--console-color) !important;
}

.purple .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: var(--console-purple-color);
}

.purple
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--console-purple-color);
  color: white;
}

.blue .mat-calendar-body-selected {
  background: var(--console-blue-color) !important;
  color: var(--console-color) !important;
}

.blue .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: var(--console-blue-color);
}

.blue
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--console-blue-color);
  color: white;
}

.light-blue .mat-calendar-body-selected {
  background: var(--console-light-blue-color) !important;
  color: var(--console-color) !important;
}

.light-blue .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: var(--console-light-blue-color);
}

.light-blue
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--console-light-blue-color);
  color: white;
}

.custom-options-box {
  margin-top: 22px !important;
  margin-left: unset;
}
